<template>
  <div>
    <admin-title title="Page Templates">
      <template v-slot:actions>
        <v-btn @click="showAddNewDialog()" color="accent gradient">Create New</v-btn>
      </template>
    </admin-title>

    <div>
      <v-row>
        <!-- CATEGORIES -->
        <v-col cols="3">
          <v-card class="custom-shadow" outlined>
            <v-card flat>
              <v-card-title class="body-1 font-weight-bold">
                <span>Categories ({{ categories.length }})</span>
                <v-spacer></v-spacer>
                <v-btn 
                  @click="getCategories()"
                  :loading="status.gettingCategories"
                  icon 
                  small
                >
                  <v-icon>mdi-refresh</v-icon>
                </v-btn>
              </v-card-title>
              <v-divider></v-divider>
              <Categories v-bind:categories.sync="categs" />
            </v-card>
            <v-divider></v-divider>
            <v-card flat>
              <v-card-title class="body-1 font-weight-bold">
                <span>Templates ({{ templates.length }})</span>
                <v-spacer></v-spacer>
                <v-btn 
                  @click="getTemplates()"
                  :loading="status.getting"
                  icon 
                  small
                >
                  <v-icon>mdi-refresh</v-icon>
                </v-btn>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text :style="{ height: '280px', overflowY: 'scroll' }">
                <preloader v-show="status.getting" message="Please wait..." />

                <PageTemplatesList v-show="!status.getting" :categories="categs" @selected="templateSelected" />
              </v-card-text>
            </v-card>
          </v-card>
        </v-col>
        
        <!-- CONTENT -->
        <v-col cols="9">
          <v-sheet max-width="750" class="mx-auto flex-grow-1">
            <v-alert v-if="!selected" type="info" border="left" text>
              Select a template
            </v-alert>

            <template-preview
              v-if="selected"
              :template="selected"
              @deleted="selected = null"
            ></template-preview>
          </v-sheet>
        </v-col>
      </v-row>
    </div>

    <!-- NEW TEMPLATE DIALOG -->
    <NewTemplateDialog ref="newTemplate" />
  </div>
</template>

<script>
import Categories from './Categories'
import { mapState, mapActions } from 'vuex'
import TemplatePreview from './TemplatePreview'
import NewTemplateDialog from './NewTemplateDialog'
import PageTemplatesList from './PageTemplatesList'

export default {
  metaInfo: {
    title: 'Page Templates'
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      search: null,
      selected: null,
      categs: [],
    }
  },

  /*------------------------------------------------------------------------------
   * COMPONENTS
   *----------------------------------------------------------------------------*/
  components: {
    Categories,
    TemplatePreview,
    NewTemplateDialog,
    PageTemplatesList
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      templates: (state) => state.pageTemplates.templates,
      categories: (state) => state.pageTemplates.categories,
      status: (state) => state.pageTemplates.status,
    }),
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('pageTemplates', [
      'getTemplates',
      'getCategories'
    ]),

    templateSelected(template) {
      this.selected = template
    },

    showAddNewDialog() {
      this.$refs.newTemplate.dialog = true
    }
  },
}
</script>