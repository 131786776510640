<template>
  <v-dialog
    v-model="dialog"
    :max-width="$dialog.content"
    :persistent="status.creating"
  >
    <v-card>
      <v-card-title>
        <h5 class="text-h5 font-weight-bold">Create New Page Template</h5>

        <close-btn @click="closeDialog()" depressed />
      </v-card-title>
      <v-card-text class="pt-3">
        <v-form ref="templateForm">
          <v-text-field
            v-model="data.name"
            :rules="[rules.required]"
            placeholder="Template Name"
            outlined
          ></v-text-field>

          <v-autocomplete
            v-model="data.categories"
            :rules="[rules.required]"
            placeholder="Categories"
            :items="refined"
            item-text="name"
            item-value="id"
            deletable-chips
            small-chips
            multiple
            outlined
            chips
          ></v-autocomplete>
        </v-form>

        <v-btn 
          @click="validateForm()"
          :loading="status.creating"
          color="accent gradient" 
          block
        >Add</v-btn>
      </v-card-text>
    </v-card> 
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { orderBy } from 'lodash'
import rules from '@/rules'

export default {
  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      rules,
      data: {},
      dialog: false,
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      categories: (state) => state.pageTemplates.categories,
      status: (state) => state.pageTemplates.status,
    }),

    refined: function () {
      return orderBy(this.categories, 'name', 'asc')
    },


  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('pageTemplates', [
      'createNewTemplate'
    ]),

    closeDialog() {
      this.dialog = false
      this.data = {}
      this.$refs.templateForm.resetValidation()
    },

    validateForm() {
      if (this.$refs.templateForm.validate()) {
        Promise.all([this.createNewTemplate(this.data)])
        .then((response) => {
          let id = response[0]
          this.$router.push({ name: 'Project', params: { id }, query: { type: 'page-template' } })
          this.closeDialog()
        })
      }
    }
  }
}
</script>